import React, { Component } from "react";
import axios from "axios";
import dotenv from "dotenv";
import {
  Container,
  Table,
  InputGroup,
  Button,
  FormControl,
} from "react-bootstrap";
dotenv.config();

const BACK_END = process.env.REACT_APP_BACKEND_URL;

class OnboardingURLS extends React.Component {
  async componentDidMount() {
    try {
      let response = await axios.get(`${BACK_END}protected/departments`, {
        headers: {
          authorization: `Bearer ${this.props.token}`,
        },
      });
      this.setState({ depts: response.data, dataLoaded: true });
    } catch (error) {
      console.log(error);
    }
  }
  state = {
    dataLoaded: false,
  };
  render() {
    if (this.state.dataLoaded) {
      return (
        <Container className="mt-5">
          <Table striped bordered hover>
            <tr>
              <th>Department</th>
              <th>Link</th>
            </tr>
            {this.state.depts.map((dept) => {
              if (dept.id != 5807) {
                return (
                  <tr>
                    <td>{dept.companyName}</td>
                    <td>
                      <InputGroup className="mb-3">
                        <FormControl
                          value={`${process.env.REACT_APP_PUBLIC_URL}register-for-access?dept=${dept.id}`}
                        />
                        <Button
                          variant="outline-secondary"
                          id={`${dept.id}btn`}
                          disabled={this.state[`${dept.id}btn`]}
                          onClick={(e) => {
                            navigator.clipboard.writeText(
                              `${process.env.REACT_APP_PUBLIC_URL}register-for-access?dept=${dept.id}`
                            );
                            let btnKey = `${dept.id}btn`;
                            let obj = {};
                            obj[btnKey] = true;
                            this.setState(obj);
                            setTimeout(() => {
                              obj[btnKey] = false;
                              this.setState(obj);
                            }, 2000);
                          }}
                        >
                          {this.state[`${dept.id}btn`] ? "Copied!" : "Copy"}
                        </Button>
                      </InputGroup>
                    </td>
                  </tr>
                );
              }
            })}
          </Table>
        </Container>
      );
    } else {
      return <h2>Loading</h2>;
    }
  }
}

export default OnboardingURLS;
