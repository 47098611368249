import React, { Component } from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import DeptRow from "./deptrow";
import Loading from "./loading";

class ModalBox extends React.Component {
  state = { selected: null };
  componentDidMount() {}
  componentDidUpdate() {
    console.log(this.state);
    console.log("Modal Props", this.props);
  }

  render() {
    if (this.props.data && !this.props.submitting) {
      return (
        <Modal
          show={this.props.show}
          onHide={this.props.hide}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="">Edit User - {this.props.data.email}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={this.props.data.name}
                  onChange={(e) => {
                    this.props.update(e, "name");
                  }}
                />
              </Form.Group>

              {this.props.data.user_role === "hod" ? (
                <div>
                  <p>Departments</p>

                  <Form.Select
                    onChange={(e) =>
                      this.setState({ selected: e.target.value })
                    }
                  >
                    <option>Select Department To Add</option>
                    {this.props.depts.map((dept) => {
                      return (
                        <option value={dept.id}>{dept.companyName}</option>
                      );
                    })}
                  </Form.Select>
                  <Button
                    onClick={(e) => {
                      this.props.addDept(this.state.selected);
                    }}
                  >
                    Add
                  </Button>
                  <Table>
                    {this.props.data.depts.map((dept, index) => {
                      return (
                        <DeptRow
                          dept={this.props.data.deptsNames[index]}
                          deptId={dept}
                          index={index}
                          remove={this.props.removeDept}
                        />
                      );
                    })}
                  </Table>
                </div>
              ) : (
                ""
              )}
              <Button
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.submitChange();
                }}
              >
                Update User
              </Button>
              <Button variant="danger" onClick={this.props.rmShow}>
                Remove User
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      );
    } else {
      return <></>;
    }
  }
}

export default ModalBox;
