import React, { Component } from "react";
import { Container, Alert } from "react-bootstrap";

import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import NewTest from "./newtest";
import Onboard from "./onboard";
import Nav from "./nav";
import Console from "./console";
import LogIn from "./login";

import { supabase } from "../supabase/supabaseClient";

import dotenv from "dotenv";
import Links from "./links";
import Tests from "./tests";
import Home from "./home";
import axios from "axios";
import Loading from "./loading";
import TestPad from "./testpad";
import Users from "./users";
import TabbedTests from "./admin/tests/tabbedTests";
import Events from "./events";
import Visitor from "./visitor";

dotenv.config();

const BACK_END = process.env.REACT_APP_BACKEND_URL;

class Main extends React.Component {
  state = {
    isLoggedIn: false,
    loading: true,
    user: null,
  };
  setLoading = (boolen) => {
    this.setState({ loading: boolen });
  };
  signOut = () => {
    supabase.auth.signOut();
    this.setState({
      isLoggedIn: false,
      token: null,
    });
  };
  handleLogin = async (email) => {
    let redirect = `${process.env.REACT_APP_PUBLIC_URL}admin`;
    if (process.env.REACT_APP_STAGE) {
      redirect = "http://localhost:3000/admin";
    }
    try {
      this.setLoading(true);

      const { error } = await supabase.auth.signIn(
        { email },
        {
          redirectTo: redirect,
        }
      );
      if (error) throw error;
      alert("Check your email for the login link!");
    } catch (error) {
      console.log(error);
      if (error.message == "Signups not allowed for this instance") {
        error.message =
          "Your email address isn't registered, please speak with Nick if you need access";
      }
      alert(error.error_description || error.message);
    } finally {
      this.setLoading(false);
    }
  };
  componentDidMount() {
    this.setLoading(true);
    let user = supabase.auth.user();
    let session = supabase.auth.session();
    if (session && session.access_token) {
      this.getUser(session.access_token);
      this.setState({
        isLoggedIn: true,
        token: session.access_token,
      });
    }
    supabase.auth.onAuthStateChange((event, session) => {
      user = supabase.auth.user();
      session = supabase.auth.session();
      if (session && session.access_token) {
        this.setState({
          isLoggedIn: true,
          token: session.access_token,
        });
      }
    });
    // console.log(session.access_token);
    this.setLoading(false);
  }
  componentDidUpdate() {
    console.log(this.state);
  }
  getUser = async (token) => {
    try {
      let user = await axios.get(`${BACK_END}protected/user`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      console.log(user);
      this.setState({ user: user.data });
    } catch (error) {
      this.setState({ user: null });
    }
  };
  render() {
    if (!this.state.loading) {
      return (
        <BrowserRouter>
          <Nav
            isLoggedIn={this.state.isLoggedIn}
            signOut={this.signOut}
            user={this.state.user}
          ></Nav>
          <Container className="container">
            <main>
              <Switch>
                <Route exact path="/register-a-test">
                  <h2>Register A Negative Test</h2>

                  <NewTest></NewTest>
                </Route>
                <Route exact path="/register-for-access">
                  <h1>Register For Access</h1>
                  <Onboard></Onboard>
                </Route>
                <Route exact path="/register-visitor">
                  <h1>Register For Visitor Access</h1>
                  <Visitor />
                </Route>
                <Route exact path="/links">
                  <Links
                    isLoggedIn={this.state.isLoggedIn}
                    user={this.state.user}
                    token={this.state.token}
                  />
                </Route>
                <Route exact path="/tests">
                  <Tests
                    isLoggedIn={this.state.isLoggedIn}
                    token={this.state.token}
                    user={this.state.user}
                  />
                </Route>
                <Route path="/admin">
                  {this.state.isLoggedIn &&
                  this.state.token &&
                  this.state.user ? (
                    <Console
                      user={this.state.user}
                      token={this.state.token}
                    ></Console>
                  ) : (
                    <LogIn handleLogIn={this.handleLogin}></LogIn>
                  )}
                </Route>
                <Route exact path="/users">
                  <Users data={this.state} token={this.state.token} />
                </Route>
                <Route exact path="/events">
                  <Events
                    data={this.state}
                    token={this.state.token}
                    user={this.state.user}
                  />
                </Route>
                <Route exact path="/welcome">
                  <Home></Home>
                </Route>
                <Route path="/">
                  <Redirect to="/welcome" /> :
                </Route>
              </Switch>
            </main>
          </Container>
        </BrowserRouter>
      );
    } else {
      return <Loading />;
    }
  }
}

export default Main;
