import React, { Component } from "react";
import {
  Table,
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import axios from "axios";
import dotenv from "dotenv";
import Loading from "./loading";
import Modal from "./modal";
import RmModal from "./reModal";

dotenv.config();

const BACK_END = process.env.REACT_APP_BACKEND_URL;

class Users extends React.Component {
  state = {
    users: null,
    modal: false,
    modalData: null,
    submitting: false,
    newUser: null,
    loading: false,
    remModal: false,
    remModalData: null,
  };
  componentDidUpdate() {
    console.log(this.state);
  }
  componentDidMount() {
    this.getData();
  }
  hide = () => {
    this.setState({ modal: false });
  };
  updateData = (e, key) => {
    let data = {
      ...this.state.modalData,
    };
    data[key] = e.target.value;
    this.setState({ modalData: data });
  };
  async getData() {
    try {
      let response = await axios.get(`${BACK_END}protected/user/all`, {
        headers: {
          authorization: `Bearer ${this.props.token}`,
        },
      });
      try {
        let deptsResponse = await axios.get(
          `${BACK_END}protected/departments`,
          {
            headers: {
              authorization: `Bearer ${this.props.token}`,
            },
          }
        );
        this.setState({ depts: deptsResponse.data });
        response.data = response.data.map((user) => {
          if (user.depts.length > 0) {
            user.deptsNames = user.depts.map((dept) => {
              return this.getDeptName(dept);
            });
          }
          return user;
        });
      } catch (error) {
        this.setState({ depts: null });
      }
      response.data = response.data.map((user) => {
        return {
          ...user,
          deptsSet: new Set(user.depts),
          deptsNamesSet: new Set(user.deptsNames),
        };
      });
      this.setState({ users: response.data });
    } catch (error) {
      this.setState({ users: null });
    }
  }
  getDeptName(deptId) {
    if (this.state.depts) {
      for (let i = 0; i < this.state.depts.length; i++) {
        const element = this.state.depts[i];
        if (element.id == deptId) {
          return element.companyName;
        }
      }
      return null;
    }
  }
  removeDept = (index) => {
    console.log("Removing", index);
    let modalData = { ...this.state.modalData };
    modalData.depts.splice(index, 1);
    modalData.deptsNames.splice(index, 1);
    this.setState({ modalData: modalData });
  };
  addDept = (deptId) => {
    console.log("Adding", deptId);
    let modalData = { ...this.state.modalData };
    modalData.deptsSet.add(deptId);
    modalData.deptsNamesSet.add(this.getDeptName(deptId));
    modalData.depts = Array.from(modalData.deptsSet);
    modalData.deptsNames = Array.from(modalData.deptsNamesSet);
    this.setState({ modalData: modalData });
  };
  submitChange = async () => {
    this.setState({ submitting: true, modal: false });
    try {
      let response = await axios.put(
        `${BACK_END}protected/user`,
        this.state.modalData,
        {
          headers: {
            authorization: `Bearer ${this.props.token}`,
          },
        }
      );
      console.log(response);
    } catch (error) {}
    this.setState({ modalData: null, submitting: false });
    this.getData();
  };
  addNewUser = async () => {
    this.setState({ loading: true });
    try {
      let response = await axios.post(
        `${BACK_END}protected/user`,
        {
          newUser: {
            email: this.state.newUser,
          },
        },
        {
          headers: {
            authorization: `Bearer ${this.props.token}`,
          },
        }
      );
      console.log(response);
      this.setState({ newUser: "", loading: false });
      this.getData();
    } catch (error) {
      console.log(error);
    }
  };
  removeUser = async () => {
    console.log(
      "Now Removing",
      this.state.remModalData.name,
      "using",
      this.props.token
    );
    this.setState({ submitting: true, modal: false, RmModal: false });
    try {
      let response = await axios.delete(
        `${BACK_END}protected/user`,

        {
          data: this.state.remModalData,
          headers: {
            authorization: `Bearer ${this.props.token}`,
          },
        }
      );
      console.log(response);
    } catch (error) {}
    this.setState({ modalData: null, remModalData: null, submitting: false });
    this.getData();
  };
  remHide = () => {
    this.setState({ remModal: false });
  };
  rmShow = () => {
    this.setState({ remModalData: this.state.modalData, remModal: true });
  };
  render() {
    if (this.state.users && !this.state.loading) {
      return (
        <>
          <Modal
            show={this.state.modal}
            hide={this.hide}
            data={this.state.modalData}
            update={this.updateData}
            submit={this.submit}
            getDeptName={this.getDeptName}
            removeDept={this.removeDept}
            addDept={this.addDept}
            depts={this.state.depts}
            submitting={this.state.submitting}
            submitChange={this.submitChange}
            rmShow={this.rmShow}
          />
          <RmModal
            show={this.state.remModal}
            hide={this.remHide}
            data={this.state.remModalData}
            remuser={this.removeUser}
          />
          <Form className="mt-3">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                  Name
                </Form.Label>
                <Form.Control
                  className="mb-2"
                  id="inlineFormInput"
                  placeholder="Email Address"
                  value={this.state.newUser}
                  onChange={(e) => {
                    this.setState({ newUser: e.target.value });
                  }}
                />
              </Col>
              <Col xs="auto">
                <Button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addNewUser();
                  }}
                  className="mb-2"
                >
                  Add New HOD
                </Button>
              </Col>
            </Row>
          </Form>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Role</th>
                <th>Depts</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map((user) => {
                return (
                  <tr>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.user_role}</td>
                    <td>
                      {user.depts.map((dept) => {
                        return <p>{this.getDeptName(dept)}</p>;
                      })}
                    </td>
                    <td>
                      <Button
                        onClick={() => {
                          this.setState({
                            modalData: user,
                            modal: true,
                          });
                        }}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      );
    } else {
      return <Loading />;
    }
  }
}

export default Users;
