import React, { Component } from "react";
import HereSite from "./hereSite";
import { Tabs, Tab } from "react-bootstrap";

class Here extends React.Component {
  componentDidMount() {
    console.log(this.props);
  }
  state = {};
  render() {
    return (
      <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mb-3">
        {this.props.sites.map((site, index) => (
          <Tab eventKey={index} title={site.siteName}>
            <HereSite site={site} />
          </Tab>
        ))}
      </Tabs>
    );
  }
}

export default Here;
