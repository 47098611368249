import React, { Component } from "react";
import Loading from "../../loading";
import TestsDept from "./testDepts";

class TestSite extends React.Component {
  state = {
    sortedDepts: null,
  };
  componentDidMount() {
    this.sortDepts(this.props.site.depts);
  }
  componentDidUpdate() {
    console.log(this.state);
  }
  sortDepts(depts) {
    let sortedDepts = depts.sort((a, b) => {
      if (a.peopleSeen >= b.peopleSeen) {
        return -1;
      }
    });

    this.setState({ sortedDepts });
  }
  render() {
    if (this.state.sortedDepts) {
      return (
        <>
          <h3>{this.props.site.siteName}</h3>
          {this.state.sortedDepts.map((dept) => (
            <TestsDept dept={dept} site={this.props.site.siteName} />
          ))}
        </>
      );
    } else {
      return <Loading />;
    }
  }
}

export default TestSite;
