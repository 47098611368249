import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";

import axios from "axios";
import dotenv from "dotenv";
import Loading from "./loading";
import Modal from "./modal";

dotenv.config();

const BACK_END = process.env.REACT_APP_BACKEND_URL;

let demoData = [{ data: "Test 15456" }, { data: "Test 2" }];

class TestPad extends React.Component {
  state = {
    users: null,
    modal: false,
    modalData: null,
    submitting: false,
  };
  componentDidUpdate() {
    console.log(this.state);
  }
  componentDidMount() {
    this.getData();
  }
  hide = () => {
    this.setState({ modal: false });
  };
  updateData = (e, key) => {
    let data = {
      ...this.state.modalData,
    };
    data[key] = e.target.value;
    this.setState({ modalData: data });
  };
  async getData() {
    try {
      let response = await axios.get(`${BACK_END}protected/user/all`, {
        headers: {
          authorization: `Bearer ${this.props.token}`,
        },
      });
      try {
        let deptsResponse = await axios.get(
          `${BACK_END}protected/departments`,
          {
            headers: {
              authorization: `Bearer ${this.props.token}`,
            },
          }
        );
        this.setState({ depts: deptsResponse.data });
        response.data = response.data.map((user) => {
          if (user.depts.length > 0) {
            user.deptsNames = user.depts.map((dept) => {
              return this.getDeptName(dept);
            });
          }
          return user;
        });
      } catch (error) {
        this.setState({ depts: null });
      }

      this.setState({ users: response.data });
    } catch (error) {
      this.setState({ users: null });
    }
  }
  getDeptName(deptId) {
    if (this.state.depts) {
      for (let i = 0; i < this.state.depts.length; i++) {
        const element = this.state.depts[i];
        if (element.id == deptId) {
          return element.companyName;
        }
      }
      return null;
    }
  }
  removeDept = (index) => {
    console.log("Removing", index);
    let modalData = { ...this.state.modalData };
    modalData.depts.splice(index, 1);
    modalData.deptsNames.splice(index, 1);
    this.setState({ modalData: modalData });
  };
  addDept = (deptId) => {
    console.log("Adding", deptId);
    let modalData = { ...this.state.modalData };
    modalData.depts.unshift(parseInt(deptId));
    modalData.deptsNames.unshift(this.getDeptName(deptId));
    this.setState({ modalData: modalData });
  };
  submitChange = async () => {
    this.setState({ submitting: true, modal: false });
    try {
      let response = await axios.put(
        `${BACK_END}protected/user`,
        this.state.modalData,
        {
          headers: {
            authorization: `Bearer ${this.props.token}`,
          },
        }
      );
      console.log(response);
    } catch (error) {}
    this.setState({ modalData: null });
    this.getData();
  };
  render() {
    if (this.state.users) {
      return (
        <>
          <Modal
            show={this.state.modal}
            hide={this.hide}
            data={this.state.modalData}
            update={this.updateData}
            submit={this.submit}
            getDeptName={this.getDeptName}
            removeDept={this.removeDept}
            addDept={this.addDept}
            depts={this.state.depts}
            submitting={this.state.submitting}
            submitChange={this.submitChange}
          />
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Role</th>
                <th>Depts</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map((user) => {
                return (
                  <tr>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.user_role}</td>
                    <td>
                      {user.depts.map((dept) => {
                        return <p>{this.getDeptName(dept)}</p>;
                      })}
                    </td>
                    <td>
                      <Button
                        onClick={() => {
                          this.setState({
                            modalData: user,
                            modal: true,
                          });
                        }}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      );
    } else {
      return <Loading />;
    }
  }
}

export default TestPad;
