import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Container, Alert, InputGroup, ProgressBar } from "react-bootstrap";
import QrReader from "react-qr-reader";

import axios from "axios";
import dotenv from "dotenv";
import placeholderImg from "../img/photoplaceholder.png";
import qrIcon from "../img/qr-code-xxl.png";

dotenv.config();

const BACK_END = process.env.REACT_APP_BACKEND_URL;

class NewTest extends React.Component {
  state = {
    formValid: false,
    submitted: false,
    uploadProgress: null,
  };
  componentDidUpdate() {
    console.log(this.state); // Log out the whole state on update
  }
  processData = async (e) => {
    e.preventDefault();
    if (this.state.formValid) {
      this.setState({ submitted: true });
      console.log("Form is complete!");
      let response = await axios.post(`${BACK_END}tests`, {
        uid: this.state.uid,
        testID: this.state.testID,
        result: this.state.result,
        photourl: this.state.photourl,
        email: this.state.email,
      });
      console.log(response);
    }
  };
  checkFormValid = () => {
    console.log("UID: ", this.state.uid);
    console.log("testID: ", this.state.testID);
    console.log("result: ", this.state.result);
    console.log("photourl: ", this.state.photourl);

    if (this.state.uid && this.state.testID && this.state.photourl) {
      console.log("Form is complete!");
      this.setState({ formValid: true });
    } else {
      console.log("Form isn't valid");
      this.setState({ formValid: false });
    }
  };
  processEmail = async (e) => {
    if (e.target.value) {
      this.setState({ email: e.target.value, emailError: false });
      let url = `${BACK_END}people/validate?email=${e.target.value
        .toLowerCase()
        .replace(/\s+/g, "")}`;
      try {
        let response = await axios.get(url);
        response.data.inDB
          ? this.setState({ uid: response.data.uid })
          : this.setState({ uid: null, emailError: true });
      } catch (error) {
        this.setState({ uid: null, emailError: true }); //Reset UID
      }
      this.checkFormValid();
    } else {
      this.setState({ email: e.target.value, emailError: false });
    }
  };
  processTestID = async (e) => {
    if (e.target.value) {
      this.setState({ testID: e.target.value, testIDError: false });
      let url = `${BACK_END}tests/validate/${e.target.value}`;
      try {
        let response = await axios.get(url);
      } catch (error) {
        this.setState({ testID: null, testIDError: true }); //Reset TestID
      }
      this.checkFormValid();
    } else {
      this.setState({ testID: e.target.value, testIDError: false });
    }
  };

  handleScan = (data) => {
    if (data) {
      this.setState({
        testID: data,
        openScanner: false,
      });
    }
  };
  handleScanError = (err) => {
    console.error(err);
  };

  handleFile = async (e) => {
    let file = e.target.files[0];
    console.log("Uploading", file.name);
    let data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "test_img");
    this.setState({ uploadProgress: 0.1 });
    try {
      let response = await axios.post(
        "https://api.cloudinary.com/v1_1/jackjury/image/upload",
        data,
        {
          onUploadProgress: (progressEvent) => {
            let progress = (progressEvent.loaded / progressEvent.total) * 100;
            this.setState({ uploadProgress: progress });
            console.log("Upload Progress " + progress);
          },
        }
      );
      this.setState({ uploadProgress: null, photourl: response.data.url });
      this.checkFormValid();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    if (this.state.submitted) {
      return (
        <h4>
          Thanks, your test has been registered, please check your email for
          further information.
        </h4>
      );
    } else {
      return (
        <Container>
          <Form>
            <Alert>
              <Alert.Heading>
                This Form is for <strong>Negative Tests Only</strong>
              </Alert.Heading>
              If you do test positive, please stay at home and contact your line
              manager as soon as possible.
            </Alert>
            <Form.Group className="mb-3" controlId="emailAddress">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onBlur={this.processEmail}
              />
              {this.state.emailError ? (
                <Alert variant="warning" className="mt-2">
                  Your email address could not be validated, you need to use the
                  same email address you registered with. Please speak with your
                  head of department if you are having problems.
                </Alert>
              ) : (
                <Form.Text className="text-muted">
                  This needs to match the email address that you registered
                  with.
                </Form.Text>
              )}
            </Form.Group>
            {/* Test ID */}
            <Form.Group className="mb-3" controlId="testID">
              <Form.Label>Test ID</Form.Label>
              <InputGroup>
                {this.state.showEmailError ? (
                  <Alert variant="danger">
                    Your test id address must be unique
                  </Alert>
                ) : (
                  <> </>
                )}
                <Form.Control
                  type="input"
                  placeholder="Enter / Scan Test ID Number"
                  value={this.state.testID}
                  onBlur={this.processTestID}
                  onChange={(e) => {
                    this.processTestID(e);
                    this.setState({ testID: e.target.value });
                  }}
                />
                <Button
                  variant="light"
                  id="scan"
                  onClick={(e) => {
                    if (this.state.openScanner) {
                      this.setState({ openScanner: false });
                    } else {
                      this.setState({ openScanner: true });
                    }
                  }}
                >
                  <img className="qr-icon" src={qrIcon} />
                </Button>
              </InputGroup>
              {this.state.testIDError ? (
                <Alert variant="warning" className="mt-2">
                  Please check you have entered a new valid test ID
                </Alert>
              ) : (
                <Form.Text className="text-muted">
                  Enter the Test ID Number{" "}
                </Form.Text>
              )}
              {this.state.openScanner ? (
                <QrReader
                  className="scanner"
                  delay={300}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{
                    width: "100%",
                  }}
                />
              ) : null}
            </Form.Group>

            {/* Photo */}
            <Form.Group controlId="formFile" className="mb-3">
              {this.state.uploadProgress ? (
                <ProgressBar
                  className="mb-3"
                  animated
                  now={this.state.uploadProgress}
                />
              ) : (
                <Form.Label>Upload a photo of your test</Form.Label>
              )}
              <Form.Control type="file" onChange={this.handleFile} />
            </Form.Group>
            <img
              className="test-img m-3"
              src={this.state.photourl ? this.state.photourl : placeholderImg}
            />
            <Button
              variant="primary"
              type="submit"
              className="mb-2"
              onClick={this.processData}
              disabled={!this.state.formValid}
            >
              Submit
            </Button>
          </Form>
        </Container>
      );
    }
  }
}

export default NewTest;
