import React, { Component } from "react";
import Loading from "./loading";
import { Container, Form, Button } from "react-bootstrap";

import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

const BACK_END = process.env.REACT_APP_BACKEND_URL;

class Events extends React.Component {
  state = {
    date: null,
    loading: false,
  };
  getData = async () => {
    this.setState({ loading: true });
    console.log(this.state.date);
    let response = await axios.get(
      `${BACK_END}protected/events?date=${this.state.date}`,
      {
        headers: {
          authorization: `Bearer ${this.props.token}`,
        },
      }
    );
    console.log(response.data);
    this.getCsv(response.data);
    this.setState({ loading: false });
  };
  getCsv = (data) => {
    let rows = [
      [
        "name",
        "department",
        "controller ID",
        "controller Name",
        "event_label",
        "created_at",
      ],
    ];
    data.forEach((event) => {
      let row = [
        event.person_name,
        null,
        event.door_controller_id,
        event.door_controller_name,
        event.event_label,
        event.created_at,
      ];
      rows.push(row);
    });
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };
  render() {
    if (
      this.props.user &&
      this.props.user.user_role == "admin" &&
      !this.state.loading
    ) {
      return (
        <Container>
          <p>You will need to use Chrome or Firefox to use this page</p>
          <Form.Control
            type="date"
            name="date_of_birth"
            placeholder="Choose a date"
            onChange={(e) => {
              this.setState({ date: e.target.value });
            }}
          />
          {this.state.date ? (
            <Button
              className="mt-3"
              onClick={(e) => {
                e.preventDefault();
                this.getData();
              }}
            >
              Get Data
            </Button>
          ) : (
            ""
          )}
        </Container>
      );
    } else {
      return <Loading />;
    }
  }
}

export default Events;
