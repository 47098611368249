import React, { Component } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import placeholderImg from "../img/photoplaceholder.png";
import axios from "axios";
import Loading from "./loading";
const BACK_END = process.env.REACT_APP_BACKEND_URL;

let cloudinary = window.cloudinary;

class Onboard extends React.Component {
  componentDidUpdate() {
    console.log(this.state); // Log out the whole state on update
  }
  state = {
    formValid: false,
    submitted: false,
    submitError: false,
  };
  processData = async (e) => {
    e.preventDefault();
    if (!this.state.mobileValid) {
      await this.processMobile();
    }
    console.log("Email", this.state.emailValid);
    if (!this.state.emailValid) {
      await this.processEmail();
    }
    if (this.checkFormValid()) {
      console.log("Form is complete!");
      this.setState({ loading: true });
      let response = await axios.post(`${BACK_END}people`, {
        email: this.state.email,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        mobile: this.state.mobile,
        image_url: this.state.photourl,
        deptID: this.state.deptID,
        nok: `${this.state.nokName} - ${this.state.nokRelationship} - ${this.state.nokNumber}`,
        job: this.state.job,
        dept: this.state.dept,
      });
      console.log(response);
      this.setState({ loading: false, submitted: true });
    } else {
      this.setState({ submitError: true });
      window.scrollTo(0, 0);
    }
  };
  checkFormValid = () => {
    this.setState({ submitError: false });
    if (
      this.state.firstName &&
      this.state.lastName &&
      this.state.mobile &&
      this.state.mobileValid &&
      this.state.dept &&
      this.state.email &&
      this.state.emailValid &&
      this.state.photourl &&
      this.state.nokName &&
      this.state.nokRelationship &&
      this.state.nokNumber
    ) {
      console.log("Form is complete!");
      this.setState({ formValid: true });
      return true;
    } else {
      console.log("Form isn't valid");
      this.setState({ formValid: false });
      return false;
    }
  };
  storeEmail = (e) => {
    let email = e.target.value.toLowerCase().replace(/\s+/g, "");
    this.setState({ email });
  };
  processEmail = async () => {
    if (this.state.email && this.state.email.length > 0) {
      let url = `${BACK_END}people/validate?email=${this.state.email}`;
      try {
        let response = await axios.get(url);
        console.log(response.data);
        response.data.inDB
          ? this.setState({ emailValid: false, showEmailError: true })
          : this.setState({ emailValid: true, showEmailError: false });
      } catch (error) {
        this.setState({ emailValid: false });
      }
      this.checkFormValid();
    } else {
      this.setState({ showEmailError: false });
    }
  };
  storeMobile = (e) => {
    let mobile = e.target.value.replace(/\s+/g, "");
    this.setState({ mobile });
  };
  processMobile = async (e) => {
    if (this.state.mobile && this.state.mobile.length > 0) {
      let url = `${BACK_END}people/validate?tel=${this.state.mobile}`;
      try {
        let response = await axios.get(url);
        response.data.inDB
          ? this.setState({ mobileValid: false, showMobileError: true })
          : this.setState({ mobileValid: true, showMobileError: false });
      } catch (error) {
        this.setState({ mobileValid: false });
      }
      this.checkFormValid();
    } else {
      this.setState({ showMobileError: false });
    }
  };
  processName = (e) => {
    let output = {};
    output[e.target.id] = e.target.value;
    this.setState(output);
    this.checkFormValid();
  };
  processNokName = (e) => {
    this.setState({ nokName: e.target.value });
    this.checkFormValid();
  };
  processNokRelationship = (e) => {
    this.setState({ nokRelationship: e.target.value });
    this.checkFormValid();
  };
  processNokNumber = (e) => {
    this.setState({ nokNumber: e.target.value });
    this.checkFormValid();
  };
  processJob = (e) => {
    this.setState({ job: e.target.value });
    this.checkFormValid();
  };
  setDeptMsg = () => {
    if (this.state.dept) {
      return [
        `You are registering as part of the ${this.state.dept} team`,
        "If this is not correct, please speak with the person who sent you this link.",
      ];
    } else if (this.state.deptError) {
      return [
        `Error`,
        "We could not find the department you are working for, please speak with the person who sent you this link.",
      ];
    }
    return ["", " please wait..."];
  };
  cloudnaryWidget = cloudinary.createUploadWidget(
    {
      cloudName: "jackjury",
      uploadPreset: "siteaccess",
      sources: [
        "local",
        "camera",
        "dropbox",
        "google_drive",
        "facebook",
        "instagram",
      ],
      cropping: true,
      croppingAspectRatio: 1.000001,
      minImageWidth: 500,
      minImageHeight: 500,
      croppingShowDimensions: true,
      croppingValidateDimensions: true,
      croppingShowBackButton: true,
      showSkipCropButton: false,
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        let photourl = result.info.url;
        this.setState({ photourl: photourl });
        this.checkFormValid();
      }
    }
  );
  async componentDidMount() {
    let searchParams = new URLSearchParams(window.location.search);
    let requiredDept = searchParams.get("dept");
    let deptList = await axios.get(`${BACK_END}departments`);
    let decodedDept = deptList.data.find((dept) => dept.id == requiredDept);
    if (decodedDept) {
      this.setState({ dept: decodedDept.companyName, deptID: decodedDept.id });
    } else {
      this.setState({ deptError: true });
    }
  }
  render() {
    if (this.state.loading) {
      return <Loading />;
    } else
      return (
        <Container>
          <Alert
            className={!this.state.submitted ? "hide" : "none"}
            variant="success"
          >
            Thanks for registering!
          </Alert>
          <Form className={this.state.submitted ? "hide" : "none"}>
            <Alert variant={this.state.deptError ? "danger" : "primary"}>
              <strong>{this.setDeptMsg()[0]}</strong>
              <p className="mt-2">{this.setDeptMsg()[1]}</p>
            </Alert>
            {this.state.submitError ? (
              <Alert variant="danger">
                <strong>
                  Please check that you have filled in all of the form, and your
                  photo is showing below
                </strong>
              </Alert>
            ) : (
              ""
            )}
            <Form.Group className="mb-3" controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="input"
                placeholder="Enter First Name"
                onInput={this.processName}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="input"
                placeholder="Enter Last Name"
                onInput={this.processName}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="job">
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                type="input"
                placeholder="Enter your Job Title"
                onInput={this.processJob}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="mobile">
              <Form.Label>Mobile Number</Form.Label>
              {this.state.showMobileError ? (
                <Alert variant="danger">
                  Your mobile number must be unique
                </Alert>
              ) : (
                <> </>
              )}

              <Form.Control
                type="tel"
                placeholder="Enter Your Mobile Number"
                onInput={this.storeMobile}
                onBlur={this.processMobile}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email Address</Form.Label>
              {this.state.showEmailError ? (
                <Alert variant="danger">
                  Your email address must be unique
                </Alert>
              ) : (
                <> </>
              )}
              <Form.Control
                type="email"
                placeholder="Enter Your Email Address"
                onInput={this.storeEmail}
                onBlur={this.processEmail}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="nok">
              <Form.Label>Next Of Kin</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                onInput={this.processNokName}
              />
              <Form.Control
                type="text"
                placeholder="Relationship"
                onInput={this.processNokRelationship}
              />
              <Form.Control
                type="text"
                placeholder="Contact Number"
                onInput={this.processNokNumber}
              />
            </Form.Group>
            <h2>Pass Photo</h2>
            <p>Please upload a photo for your site pass</p>
            <Button variant="secondary" onClick={this.cloudnaryWidget.open}>
              Choose your Photo
            </Button>
            <img
              className="photo-img m-3"
              src={this.state.photourl || placeholderImg}
            />
            <p>
              By completing this form, you are agreeing to our{" "}
              <a
                href="https://www.secretcinema.org/privacy-policy"
                target="_blank"
              >
                privacy policy.
              </a>
            </p>
            <Button
              variant="primary"
              type="submit"
              className="mb-2"
              onClick={this.processData}
              // disabled={!this.state.formValid}
            >
              Submit
            </Button>
          </Form>
        </Container>
      );
  }
}

export default Onboard;
