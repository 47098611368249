import React, { Component } from "react";
import { Redirect } from "react-router";
import OnboardingURLS from "./onboardingURLS";

class Links extends React.Component {
  render() {
    if (this.props.isLoggedIn && this.props.user) {
      return <OnboardingURLS token={this.props.token} />;
    } else {
      return <h2>You need to be logged in and an admin to view this page</h2>;
    }
  }
}
export default Links;
