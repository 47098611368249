import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import dotenv from "dotenv";

dotenv.config();

class logIn extends React.Component {
  state = {
    submitted: false,
  };
  componentDidMount() {}
  componentDidUpdate() {
    console.log(this.state);
  }
  processEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  render() {
    if (this.state.submitted) {
      return <p>Thank You</p>;
    } else {
      return (
        <Form>
          <h2>Log In</h2>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={this.processEmail}
            />
            <Form.Text className="text-muted">
              We will send a log in link
            </Form.Text>
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ submitted: true });
              this.props.handleLogIn(this.state.email);
            }}
          >
            Log In
          </Button>
        </Form>
      );
    }
  }
}
export default logIn;
