import React, { Component } from "react";
import InlinePhoto from "./inlinePhoto";
import TimeAgo from "react-time-ago";

class TestRow extends React.Component {
  componentDidMount() {
    if (this.props.tests) {
      console.log(this.props.tests.created_at);
    }
  }
  formatDate(time) {
    let daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let date = new Date(time);
    // Monday 1st Novmber 22:30
    let string = `${daysOfWeek[date.getDay()]} ${date
      .getDate()
      .toString()
      .padStart(2, "0")} ${months[date.getMonth()]} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    return string;
  }
  render() {
    return (
      <tr>
        {this.props.admin ? (
          <td>
            <input
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  this.props.addUser(this.props.data.id);
                } else {
                  this.props.removeUser(this.props.data.id);
                }
              }}
            />
          </td>
        ) : (
          ""
        )}
        <td>{this.props.data.name}</td>
        <td>
          {this.props.data.lastSeen.Dagenham
            ? this.formatDate(this.props.data.lastSeen.Dagenham)
            : ""}
        </td>
        <td>
          {this.props.data.tests ? (
            <p>{this.formatDate(this.props.data.tests.created_at)}</p>
          ) : (
            "No current test"
          )}
        </td>
        <td>
          {this.props.data.tests ? (
            <>
              <InlinePhoto
                url={this.props.data.tests.photo_url}
                name={this.props.data.name}
              />
            </>
          ) : (
            <></>
          )}
        </td>
        {this.props.data.enhanced ? <td>{this.props.data.enhanced}</td> : <></>}
      </tr>
    );
  }
}

export default TestRow;
