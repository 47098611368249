import React, { Component } from "react";
import { Spinner, Container } from "react-bootstrap";

class Loading extends React.Component {
  render() {
    return (
      <Container>
        <Spinner className="mt-2" animation="border" />
      </Container>
    );
  }
}

export default Loading;
