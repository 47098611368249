import React, { Component } from "react";
import HereSite from "./hereSite";

class Here extends React.Component {
  componentDidMount() {
    console.log(this.props);
  }
  state = {};
  render() {
    return (
      <div>
        {this.props.sites.map((site) => (
          <HereSite site={site} />
        ))}
      </div>
    );
  }
}

export default Here;
