import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Loading from "./loading";

class RmModal extends React.Component {
  render() {
    if (this.props.data) {
      return (
        <Modal
          show={this.props.show}
          onHide={this.props.hide}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You are about remove user {this.props.data.email}, are you sure?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.hide}>
              No
            </Button>
            <Button variant="danger" onClick={this.props.remuser}>
              Remove User
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return <></>;
    }
  }
}

export default RmModal;
