import React, { Component } from "react";
import axios from "axios";
import Here from "./here";
import Loading from "../loading";
import TabbedTests from "./tests/tabbedTests";

const BACK_END = process.env.REACT_APP_BACKEND_URL;

const path = window.location.pathname;

class Admin extends React.Component {
  state = {
    loading: true,
  };
  componentDidUpdate() {
    console.log(this.state);
  }
  componentDidMount() {
    console.log(path);
    this.getData();
  }
  async getData() {
    this.setState({ loading: true });
    let people = [];
    let tests = {};
    // People
    try {
      let response = await axios.get(`${BACK_END}protected/people`, {
        headers: {
          authorization: `Bearer ${this.props.token}`,
        },
      });
      people = response.data;
      // Tests
      try {
        let response = await axios.get(`${BACK_END}protected/tests/current`, {
          headers: {
            authorization: `Bearer ${this.props.token}`,
          },
        });
        response.data.forEach((test) => {
          tests[test.user_id] = test;
        });
        people = people.map((person) => {
          return {
            ...person,
            tests: tests[person.id] || null,
          };
        });
        this.setState({ people: people });
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
    // Sites
    try {
      let response = await axios.get(`${BACK_END}protected/sites`, {
        headers: {
          authorization: `Bearer ${this.props.token}`,
        },
      });
      let sites = response.data;
      this.setState({ sites, loading: false });
    } catch (error) {
      this.setState({ sites: null });
    }

    this.sortPeople();
  }
  sortPeople() {
    console.log("Sorting people");
    let sites = this.state.sites;
    sites.forEach((site) => {
      let depts = site.depts;
      let peopleData = this.state.people;

      depts.forEach((dept) => {
        let deptID = dept.id;
        let output = [];
        let peopleOnsite = 0;
        let peopleSeen = 0;
        // Iterate over each person and if check if they are in this dept.
        peopleData.forEach((person) => {
          for (let i = 0; i < person.deptId.length; i++) {
            const element = person.deptId[i];
            if (element == deptID) {
              output.push(person);
              if (person.lastSeen[site.siteName]) {
                peopleSeen = peopleSeen + 1;
              }
            }
          }
        });
        dept.people = output;
        dept.peopleSeen = peopleSeen;
      });
    });

    this.setState({ sortedSites: sites });
  }
  render() {
    if (!this.state.loading && path === "/admin/tests") {
      return (
        <>
          <h2>Hello Admin</h2>
          <p>
            Use the links above to access the onboarding urls, or to view all
            tests
          </p>
          {this.state.sortedSites ? (
            <TabbedTests sites={this.state.sortedSites} />
          ) : (
            <Loading />
          )}
        </>
      );
    } else if (!this.state.loading) {
      return (
        <>
          <h2>Hello Admin</h2>
          <p>
            Use the links above to access the onboarding urls, or to view all
            tests
          </p>
          {this.state.sortedSites ? (
            <Here sites={this.state.sortedSites} />
          ) : (
            // <TabbedTests sites={this.state.sortedSites} />
            <Loading />
          )}
        </>
      );
    } else {
      return <Loading />;
    }
  }
}

export default Admin;
