import React, { Component } from "react";
import { Table, Container, Form } from "react-bootstrap";
import TestRow from "./testsrow";
import Loading from "./loading";
import EnhancedInline from "./enhancedinline";
const axios = require("axios");
const BACK_END = process.env.REACT_APP_BACKEND_URL;

class Tests extends React.Component {
  state = {
    filtered: false,
    selected: new Set(),
  };
  componentDidMount() {
    setTimeout(() => {
      this.getData(); // Hacky work around!
    }, 1000);
  }
  componentDidUpdate() {
    console.log(this.state);
  }
  async getData() {
    // People
    let people = [];
    let tests = {};
    let enhanced = {};
    try {
      let response = await axios.get(`${BACK_END}protected/people`, {
        headers: {
          authorization: `Bearer ${this.props.token}`,
        },
      });
      people = response.data;
      // Tests
      try {
        let response = await axios.get(`${BACK_END}protected/tests/current`, {
          headers: {
            authorization: `Bearer ${this.props.token}`,
          },
        });
        response.data.forEach((test) => {
          tests[test.user_id] = test;
        });
        try {
          let response = await axios.get(`${BACK_END}protected/enhanced`, {
            headers: {
              authorization: `Bearer ${this.props.token}`,
            },
          });
          response.data.forEach((row) => {
            enhanced[row.user_id] = row.expires;
          });

          people = people.map((person) => {
            return {
              ...person,
              tests: tests[person.id] || null,
              enhanced: enhanced[person.id] || null,
            };
          });
          // Sort alphabetically by surname
          people = people.sort((a, b) => {
            if (a.name.split(" ")[1] && b.name.split(" ")[1]) {
              if (a.name.split(" ")[1] < b.name.split(" ")[1]) {
                return -1;
              }
              if (a.name.split(" ")[1] > b.name.split(" ")[1]) {
                return 1;
              }
            } else {
              return -2;
            }
          });
          let admin = this.props.user.user_role === "admin";
          console.log("Admin", admin);
          this.setState({ people: people });
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {}
  }
  filterData = (e) => {
    if (e.target.value) {
      let filtered = this.state.people.filter((element) => {
        return element.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      this.setState({ filtered: filtered });
    } else {
      this.setState({ filtered: false });
    }
  };
  addUser = (user) => {
    let set = this.state.selected;
    set.add(user);
    this.setState({ selected: set });
  };
  removeUser = (user) => {
    let set = this.state.selected;
    set.delete(user);
    this.setState({ selected: set });
  };
  setEnhancedEndDate = (date) => {
    this.setState({ enhancedEndDate: date });
  };
  processEnhanced = async () => {
    console.log(this.state.enhancedEndDate, this.state.selected);
    this.setState({ loading: true });
    try {
      let response = await axios.post(
        `${BACK_END}protected/enhanced`,
        {
          selected: Array.from(this.state.selected),
          expiry: this.state.enhancedEndDate,
        },
        {
          headers: {
            authorization: `Bearer ${this.props.token}`,
          },
        }
      );
      console.log(response);
      this.setState({ selected: new Set(), loading: false });
      this.getData();
      this.resetAllChecks();
    } catch (error) {
      console.log(error);
    }
  };
  resetAllChecks = () => {
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
  };
  render() {
    if (this.props.isLoggedIn && this.state.people && this.props.user) {
      return (
        <Container>
          {this.state.selected.size > 0 ? (
            <EnhancedInline
              data={this.state.selected}
              setEnhancedEndDate={this.setEnhancedEndDate}
              processEnhanced={this.processEnhanced}
            />
          ) : (
            <></>
          )}
          <Form.Group className="mb-3 mt-3">
            <Form.Control
              type="text"
              placeholder="Filter By Name"
              onChange={this.filterData}
            />
          </Form.Group>

          <Table>
            <thead>
              <tr>
                <th>{this.props.user.user_role === "admin" ? "☑️" : " "}</th>
                <th>Name</th>
                <th>Last Seen</th>
                <th>Last Test</th>
                <th>Photo</th>
                <th>
                  {this.props.user.user_role === "admin" ? "Enhanced?" : " "}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.filtered
                ? this.state.filtered.map((person) => {
                    return (
                      <TestRow
                        data={person}
                        admin={this.props.user.user_role === "admin"}
                        addUser={this.addUser}
                        removeUser={this.removeUser}
                      />
                    );
                  })
                : this.state.people.map((person) => {
                    return (
                      <TestRow
                        data={person}
                        admin={this.props.user.user_role === "admin"}
                        addUser={this.addUser}
                        removeUser={this.removeUser}
                      />
                    );
                  })}
            </tbody>
          </Table>
        </Container>
      );
    } else if (!this.props.isLoggedIn) {
      return <h2>You need to be logged in to view this page</h2>;
    } else {
      return <Loading />;
    }
  }
}
export default Tests;
