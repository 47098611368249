import React, { Component } from "react";
import { ListGroup, Card, Table } from "react-bootstrap";
import Loading from "../../loading";
import TestRow from "../../testsrow";

class TestGroup extends React.Component {
  state = {
    onsite: null,
    seenToday: null,
  };
  componentDidMount() {
    console.log("Depts", this.props);
  }

  render() {
    if (this.state) {
      return (
        <Card className="mt-2" style={{ width: "90%" }}>
          <Card.Header>
            {this.props.dept.companyName} - {this.props.site}
          </Card.Header>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Last Seen</th>
                <th>Last Test</th>
                <th>Photo</th>
              </tr>
            </thead>
            <tbody>
              {this.props.dept.people.map((person) => {
                return <TestRow data={person} />;
              })}
            </tbody>
          </Table>
        </Card>
      );
    } else {
      return <Loading />;
    }
  }
}

export default TestGroup;
