import React, { Component } from "react";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";

class InlinePhoto extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="auto"
        overlay={
          <Popover id="popover-basic">
            <Popover.Header as="h3">Test - {this.props.name}</Popover.Header>
            <Popover.Body>
              <a href={this.props.url} target="_blank">
                <img src={this.props.url} className="pop-img" />
              </a>
            </Popover.Body>
          </Popover>
        }
      >
        <Button variant="success">View Photo</Button>
      </OverlayTrigger>
    );
  }
}

export default InlinePhoto;
