import React, { Component } from "react";
import Admin from "./admin/admin";
import HOD from "./hod/hod";
import Loading from "./loading";

class Console extends React.Component {
  state = {
    loading: false,
  };
  componentDidMount() {
    console.log("Console", this.props);
  }
  render() {
    if (this.props.user && this.props.token) {
      if (
        this.props.user.user_role === "admin" ||
        this.props.user.user_role === "hod"
      ) {
        return (
          <div>
            <Admin token={this.props.token} />
          </div>
        );
      }
    } else {
      return (
        <>
          <Loading />
        </>
      );
    }
  }
}

export default Console;
