import React, { Component } from "react";
import { ListGroup, Card } from "react-bootstrap";
import HereDept from "./hereDept";

class HereSite extends React.Component {
  render() {
    return (
      <>
        <h3>{this.props.site.siteName}</h3>
        {this.props.site.depts.map((dept) => (
          <HereDept dept={dept} site={this.props.site.siteName} />
        ))}
      </>
    );
  }
}

export default HereSite;
