import React, { Component } from "react";
import TestSites from "./testsSite";
import { Tabs, Tab } from "react-bootstrap";

class TabbedTests extends React.Component {
  componentDidMount() {
    console.log(this.props);
  }
  state = {};
  render() {
    return (
      <Tabs defaultActiveKey="1" className="mb-3">
        {this.props.sites.map((site, index) => (
          <Tab eventKey={index} title={site.siteName}>
            <TestSites site={site} />
          </Tab>
        ))}
      </Tabs>
    );
  }
}

export default TabbedTests;
