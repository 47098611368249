import React, { Component } from "react";
import { ListGroup, Card } from "react-bootstrap";
import Loading from "../loading";

class HereGroup extends React.Component {
  state = {
    onsite: null,
    seenToday: null,
  };
  componentDidMount() {
    let onsite = this.props.dept.people.filter((person) => {
      return person.presence[this.props.site];
    });
    let seenToday = this.props.dept.people.filter((person) => {
      return (
        person.lastSeen[this.props.site] && !person.presence[this.props.site]
      );
    });
    this.setState({ onsite, seenToday });
  }

  render() {
    if (this.state.onsite && this.state.seenToday) {
      return (
        <Card className="mt-2" style={{ width: "90%" }}>
          <Card.Header>
            {this.props.dept.companyName} - {this.props.site}
          </Card.Header>

          <ListGroup variant="flush">
            {this.state.onsite.map((person) => {
              return (
                <ListGroup.Item>
                  <input type="checkbox" className="m-2" />
                  {person.name}
                </ListGroup.Item>
              );
            })}
            {this.state.seenToday.map((person) => {
              return (
                <ListGroup.Item disabled>
                  {person.name} - {person.lastSeen[this.props.site]}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card>
      );
    } else {
      return <Loading />;
    }
  }
}

export default HereGroup;
