import React, { Component } from "react";
import { Container, Nav, Navbar, Button, NavDropdown } from "react-bootstrap";
import logo from "../img/sclogo.jpeg";
import { supabase } from "../supabase/supabaseClient";

class NavBar extends Component {
  state = {};
  render() {
    if (this.props.isLoggedIn && this.props.user) {
      return (
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} className="logo" />
              Secret Pass
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav className="justify-content-end">
                <Nav.Link href="/register-a-test">Register A Test</Nav.Link>
                <NavDropdown title="Admin" id="nav-dropdown">
                  <Nav.Link href="/admin">Admin Home</Nav.Link>
                  <Nav.Link href="/admin/tests">
                    View Tests by Department
                  </Nav.Link>
                  <Nav.Link href="/tests">View All Tests</Nav.Link>

                  <Nav.Link href="/links">Onboarding Links</Nav.Link>
                  {this.props.user.user_role === "admin" ? (
                    <Nav.Link href="/users">Edit Users</Nav.Link>
                  ) : (
                    <></>
                  )}
                  {this.props.user.user_role === "admin" ? (
                    <Nav.Link href="/events">Export Events</Nav.Link>
                  ) : (
                    <></>
                  )}
                </NavDropdown>
                <Button
                  onClick={() => {
                    this.props.signOut();
                  }}
                >
                  Log Out
                </Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
    } else {
      return (
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} className="logo" />
              Secret Pass
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/register-a-test">Register A Test</Nav.Link>
              </Nav>
              <Nav className="me-auto">
                <Nav.Link href="/admin">Admin</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
    }
  }
}

export default NavBar;
