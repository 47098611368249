import React, { Component } from "react";
import Main from "./components/main";
import "bootswatch/dist/darkly/bootstrap.css";

class App extends React.Component {
  render() {
    return <Main></Main>;
  }
}

export default App;
