import React, { Component } from "react";
import { Card, Container } from "react-bootstrap";

class Home extends React.Component {
  render() {
    return (
      <Container>
        <header>
          <h1>
            Welcome to Secret Cinema Show/Build ID pass and lateral flow test
            registration site.
          </h1>
        </header>
        <h2>Secret ID Pass</h2>
        <Card>
          <p>
            Before coming to site every member of staff must register for a
            Secret ID Pass.
          </p>
          <p>
            All HODs must please send the relevant onboarding links to the
            theirs teams so that they can register. (Please note a HOD must use
            the HOD link, rather than their department).
          </p>
        </Card>
        <h2>Inductions</h2>
        <Card>
          <p>
            When any new member of staff arrives onto site for the first time,
            they will be given an induction, after their induction they will be
            handed you show pass that has been linked to their SC ID account.
          </p>

          <p>
            All staff must use their Secret ID Pass to tap in/out. The systems
            keep a running log of who is on site at any one time and it is vital
            that all staff tap in/out each time they leave and return.
          </p>
        </Card>
        <h2>COVID -19</h2>
        <Card>
          <p>
            It is Secret Cinema’s policy that every member of staff does a
            lateral flow test before coming to site, and twice a week for those
            working on site daily.
          </p>

          <p>
            All staff must register their negative test result by clicking
            ‘register a test.’ Please use the same email you registered with and
            upload a photo on the test and the test ID. If a member of staff
            does not upload a valid test, their ID profile will become inactive,
            and their Secret ID Pass will not grant them access onto site.
          </p>
        </Card>
        <h2>The Site Rules</h2>

        <Card>
          <p>
            All staff must read the staff handbook before coming to site. (link
            to follow)
          </p>

          <p>
            If you have any questions on the accreditation process/system please
            email Nick Williams,{" "}
            <a href="mailto:Scandal.pc@secretcinema.org">
              Scandal.pc@secretcinema.org
            </a>
          </p>
        </Card>
      </Container>
    );
  }
}

export default Home;
