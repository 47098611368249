import React, { Component } from "react";
import { Button } from "react-bootstrap";

class DeptRow extends React.Component {
  render() {
    return (
      <tr>
        <td>{this.props.dept}</td>
        <td>
          <Button
            onClick={(e) => {
              this.props.remove(this.props.index);
            }}
          >
            Remove
          </Button>
        </td>
      </tr>
    );
  }
}

export default DeptRow;
