import React, { Component } from "react";
import {
  Form,
  Button,
  Container,
  Alert,
  InputGroup,
  ProgressBar,
} from "react-bootstrap";
import placeholderImg from "../img/photoplaceholder.png";
import axios from "axios";
import Loading from "./loading";

import QrReader from "react-qr-reader";

import qrIcon from "../img/qr-code-xxl.png";

const BACK_END = process.env.REACT_APP_BACKEND_URL;

let cloudinary = window.cloudinary;

class Visitor extends React.Component {
  componentDidUpdate() {
    console.log(this.state); // Log out the whole state on update
  }
  state = {
    formValid: false,
    submitted: false,
    submitError: false,
  };
  processData = async (e) => {
    e.preventDefault();
    if (!this.state.mobileValid) {
      await this.processMobile();
    }
    console.log("Email", this.state.emailValid);
    if (!this.state.emailValid) {
      await this.processEmail();
    }
    if (this.checkFormValid()) {
      console.log("Form is complete!");
      this.setState({ loading: true });
      let response = await axios.post(`${BACK_END}visitor`, {
        email: this.state.email,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        mobile: this.state.mobile,
        visitorDetails: `From ${this.state.company}, visiting ${this.state.visiting}`,
        testID: this.state.testID,
        testPhoto: this.state.photourl,
      });
      console.log(response);
      this.setState({ loading: false, submitted: true });
    } else {
      this.setState({ submitError: true });
      window.scrollTo(0, 0);
    }
  };
  checkFormValid = () => {
    this.setState({ submitError: false });
    if (
      this.state.firstName &&
      this.state.lastName &&
      this.state.company &&
      this.state.visiting &&
      this.state.mobile &&
      this.state.mobileValid &&
      this.state.email &&
      this.state.emailValid &&
      this.state.testID &&
      this.state.photourl
    ) {
      console.log("Form is complete!");
      this.setState({ formValid: true });
      return true;
    } else {
      console.log("Form isn't valid");
      this.setState({ formValid: false });
      return false;
    }
  };
  storeEmail = (e) => {
    let email = e.target.value.toLowerCase().replace(/\s+/g, "");
    this.setState({ email });
  };
  processEmail = async () => {
    if (this.state.email && this.state.email.length > 0) {
      let url = `${BACK_END}people/validate?email=${this.state.email}`;
      try {
        let response = await axios.get(url);
        console.log(response.data);
        response.data.inDB
          ? this.setState({ emailValid: false, showEmailError: true })
          : this.setState({ emailValid: true, showEmailError: false });
      } catch (error) {
        this.setState({ emailValid: false });
      }
      this.checkFormValid();
    } else {
      this.setState({ showEmailError: false });
    }
  };
  storeMobile = (e) => {
    let mobile = e.target.value.replace(/\s+/g, "");
    this.setState({ mobile });
  };
  processMobile = async (e) => {
    if (this.state.mobile && this.state.mobile.length > 0) {
      let url = `${BACK_END}people/validate?tel=${this.state.mobile}`;
      try {
        let response = await axios.get(url);
        response.data.inDB
          ? this.setState({ mobileValid: false, showMobileError: true })
          : this.setState({ mobileValid: true, showMobileError: false });
      } catch (error) {
        this.setState({ mobileValid: false });
      }
      this.checkFormValid();
    } else {
      this.setState({ showMobileError: false });
    }
  };
  processName = (e) => {
    let output = {};
    output[e.target.id] = e.target.value;
    this.setState(output);
    this.checkFormValid();
  };
  processVisiting = (e) => {
    this.setState({ visiting: e.target.value });
    this.checkFormValid();
  };
  processCompany = (e) => {
    this.setState({ company: e.target.value });
    this.checkFormValid();
  };

  processTestID = async (e) => {
    if (e.target.value) {
      this.setState({ testID: e.target.value, testIDError: false });
      let url = `${BACK_END}tests/validate/${e.target.value}`;
      try {
        let response = await axios.get(url);
      } catch (error) {
        this.setState({ testID: null, testIDError: true }); //Reset TestID
      }
      this.checkFormValid();
    } else {
      this.setState({ testID: e.target.value, testIDError: false });
    }
  };

  handleScan = (data) => {
    if (data) {
      this.setState({
        testID: data,
        openScanner: false,
      });
    }
  };
  handleScanError = (err) => {
    console.error(err);
  };

  handleFile = async (e) => {
    let file = e.target.files[0];
    console.log("Uploading", file.name);
    let data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "test_img");
    this.setState({ uploadProgress: 0.1 });
    try {
      let response = await axios.post(
        "https://api.cloudinary.com/v1_1/jackjury/image/upload",
        data,
        {
          onUploadProgress: (progressEvent) => {
            let progress = (progressEvent.loaded / progressEvent.total) * 100;
            this.setState({ uploadProgress: progress });
            console.log("Upload Progress " + progress);
          },
        }
      );
      this.setState({ uploadProgress: null, photourl: response.data.url });
      this.checkFormValid();
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidMount() {}
  render() {
    if (this.state.loading) {
      return <Loading />;
    } else
      return (
        <Container className="mt-5">
          <Alert
            className={!this.state.submitted ? "hide" : "none"}
            variant="success"
          >
            Thanks for registering!
          </Alert>
          <Form className={this.state.submitted ? "hide" : "none"}>
            {this.state.submitError ? (
              <Alert variant="danger">
                <strong>
                  Please check that you have filled in all of the form, and your
                  photo is showing below
                </strong>
              </Alert>
            ) : (
              ""
            )}
            <Alert variant={"primary"}>
              <strong>You are registering as a visitor</strong>
              <p className="mt-2">
                You will need to register a lateral flow test that was taken on
                the day of your visit in order to access the site.
              </p>
            </Alert>
            <h3 className="mb-2">Your Details</h3>
            <Form.Group className="mb-3" controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="input"
                placeholder="Enter First Name"
                onInput={this.processName}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="input"
                placeholder="Enter Last Name"
                onInput={this.processName}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="company">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="input"
                placeholder="Enter your Company Name"
                onInput={this.processCompany}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="mobile">
              <Form.Label>Mobile Number</Form.Label>
              {this.state.showMobileError ? (
                <Alert variant="danger">
                  Your mobile number must be unique
                </Alert>
              ) : (
                <> </>
              )}

              <Form.Control
                type="tel"
                placeholder="Enter your Mobile Number"
                onInput={this.storeMobile}
                onBlur={this.processMobile}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email Address</Form.Label>
              {this.state.showEmailError ? (
                <Alert variant="danger">
                  It looks like you are already registered. To register a new
                  test click{" "}
                  <a
                    href={`${process.env.REACT_APP_PUBLIC_URL}register-a-test`}
                  >
                    here
                  </a>
                </Alert>
              ) : (
                <> </>
              )}
              <Form.Control
                type="email"
                placeholder="Enter your Email Address"
                onInput={this.storeEmail}
                onBlur={this.processEmail}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="company">
              <Form.Label>Who are you Visiting?</Form.Label>
              <Form.Control
                type="input"
                placeholder="Enter The Name Of The Person You Are Visiting"
                onInput={this.processVisiting}
              />
            </Form.Group>
            {/* Test ID */}
            <h3 className="mb-2">Your COVID Lateral Flow Test</h3>
            <Form.Group className="mb-3" controlId="testID">
              <Form.Label>Test ID</Form.Label>
              <InputGroup>
                <Form.Control
                  type="input"
                  placeholder="Enter / Scan Test ID Number"
                  value={this.state.testID}
                  onBlur={this.processTestID}
                  onChange={(e) => {
                    this.processTestID(e);
                    this.setState({ testID: e.target.value });
                  }}
                />
                <Button
                  variant="light"
                  id="scan"
                  onClick={(e) => {
                    if (this.state.openScanner) {
                      this.setState({ openScanner: false });
                    } else {
                      this.setState({ openScanner: true });
                    }
                  }}
                >
                  <img className="qr-icon" src={qrIcon} />
                </Button>
              </InputGroup>
              {this.state.testIDError ? (
                <Alert variant="warning" className="mt-2">
                  Please check you have entered a new valid test ID
                </Alert>
              ) : (
                <Form.Text className="text-muted">
                  Enter the Test ID Number{" "}
                </Form.Text>
              )}
              {this.state.openScanner ? (
                <QrReader
                  className="scanner"
                  delay={300}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{
                    width: "100%",
                  }}
                />
              ) : null}
            </Form.Group>

            {/* Photo */}
            <Form.Group controlId="formFile" className="mb-3">
              {this.state.uploadProgress ? (
                <ProgressBar
                  className="mb-3"
                  animated
                  now={this.state.uploadProgress}
                />
              ) : (
                <Form.Label>Upload a photo of your test</Form.Label>
              )}
              <Form.Control type="file" onChange={this.handleFile} />
            </Form.Group>
            <img
              className="test-img m-3"
              src={this.state.photourl ? this.state.photourl : placeholderImg}
            />
            <p>
              By completing this form, you are agreeing to our{" "}
              <a
                href="https://www.secretcinema.org/privacy-policy"
                target="_blank"
              >
                privacy policy.
              </a>
            </p>
            <Button
              variant="primary"
              type="submit"
              className="mb-2"
              onClick={this.processData}
              // disabled={!this.state.formValid}
            >
              Submit
            </Button>
          </Form>
        </Container>
      );
  }
}

export default Visitor;
