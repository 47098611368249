import React, { Component } from "react";
import {
  Col,
  Form,
  Row,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";

class EnhancedInline extends React.Component {
  state = {
    complete: false,
  };
  componentDidUpdate() {
    console.log(this.state);
  }
  render() {
    return (
      <Form className="mt-3">
        <Row className="align-items-center">
          <Col sm={5} className="my-1">
            <h5>
              Adding {this.props.data.size} users to enhanced testing until:
            </h5>
          </Col>
          <Col sm={3} className="my-1">
            <InputGroup>
              <Form.Control
                type="date"
                onChange={(e) => {
                  this.props.setEnhancedEndDate(new Date(e.target.value));
                  if (e.target.value) {
                    this.setState({ complete: true });
                  } else {
                    this.setState({ complete: false });
                  }
                }}
              />
            </InputGroup>
          </Col>

          <Col xs="auto" className="my-1">
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.processEnhanced();
              }}
              disabled={!this.state.complete}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default EnhancedInline;
